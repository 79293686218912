import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExternalResourcesService {
  private loadScript(str: string): Observable<void> {
    return new Observable((observer) => {
      const script = document.createElement('script');
      script.async = true;
      script.type = 'text/javascript';
      script.innerHTML = str;
      script.addEventListener('load', () => {
        observer.next();
        observer.complete();
      });

      document.head.appendChild(script);
    });
  }

  public loadResource(src: string): Observable<void> {
    return new Observable((observer) => {
      const script = document.createElement('script');
      script.async = true;
      script.type = 'text/javascript';
      script.src = src;
      script.addEventListener('load', () => {
        observer.next();
        observer.complete();
      });

      document.head.appendChild(script);
    });
  }

  private patchDocumentWrite(id: string = null): void {
    document.write = (input: string): void => {
      id ? (document.getElementById(id).innerHTML += input) : (document.body.innerHTML += input);
    };
  }

  private patchDocumentWriteAlternatively(): void {
    document.write = (input: string): void => {
      document.body.innerHTML += input;
    };
  }

  public loadExternalResources(component: string): Observable<void> {
    if (!Element.prototype.remove) {
      Element.prototype.remove = function remove() {
        if (this.parentNode) {
          this.parentNode.removeChild(this);
        }
      };
    }

    const elem = document.getElementsByTagName('iframe');

    for (var i = 0; i < elem.length; i++) {
      elem[i].remove();
    }

    switch (component) {
      case 'root': {
        return of(null).pipe(
          tap(() => this.patchDocumentWrite()),
          switchMap(() => this.loadResource('assets/scripts/google-tag-manager.js')),
          switchMap(() => this.loadResource('assets/scripts/mailru-counter.js')),
        );
      }

      case 'configurator':
        return of(null).pipe(switchMap(() => this.loadResource('assets/scripts/bitrix-chat.js')));

      default:
        return null;
    }
  }
}
