import { NgModule } from '@angular/core';
import { LoadMediaPipe } from './load-media.pipe';
import { CommonModule } from '@angular/common';
import { NoComma } from './no-comma.pipe';
import { NumberPipe } from './number.pipe';
import { CcyPipe } from './ccy.pipe';
import { SortProjectsPipe } from './sort-projects.pipe';
import { ScreenLessThanPipe } from './screen-less-than.pipe';
import { YearsPipe } from './years.pipe';

@NgModule({
  declarations: [
    LoadMediaPipe,
    NoComma,
    NumberPipe,
    CcyPipe,
    SortProjectsPipe,
    ScreenLessThanPipe,
    YearsPipe,
  ],
  imports: [CommonModule],
  exports: [
    LoadMediaPipe,
    NoComma,
    NumberPipe,
    CcyPipe,
    SortProjectsPipe,
    ScreenLessThanPipe,
    YearsPipe,
  ],
})
export class PipesModule {}
